export default {
  heading: 'Red Hat Display, san-serif',
  body: 'Noto Sans KR, san-serif',
  monospace: 'Menlo, monospace',
  display: 'Roboto Condensed, serif',
  handwriting: 'Pinyon Script, serif',
  googleFonts: [
    'Red Hat Display:100,200,300,400,500,600,700,800,900',
    'Courier Prime:400,500,600,700',
    'Roboto Condensed:300,500,600,700',
    'Pinyon Script',
    'Noto Sans:100,200,300,400,500,600,700,800,900'
  ]
  // customFamilies: [''],
  // customUrls: [''],
}
