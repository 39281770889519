export default {
  // text: '#1a1a1a',
  // primary: '#9d8076', //'#a59b87',
  // secondary: '#e7e7e7',
  // background: '#fafafa', // #e1e1e1
  // backgroundSecondary: 'lightgrey',
  // light: '#fdfdfd',
  // dark: '#020303',

  text: '#2f353d',
  primary: '#cf5f29',
  primaryLight: '#d57b06',
  secondary: '#021b60',
  background: '#fff7e9',
  backgroundSecondary: '#262e38',
  light: '#FFF',
  dark: '#020303'
}
