export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 1px',
    borderColor: 'primary',
    fontWeight: '500',
    padding: ['0.75rem 2rem'],
    fontSize: '1rem',
    textTransform: 'capitalize',
    borderRadius: '500px',
    ':hover': {
      backgroundColor: 'backgroundSecondary',
      borderColor: 'backgroundSecondary',
      color: 'light'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '0.5rem',
    color: 'primary',
    border: 'none',
    borderBottom: 'solid 2px',
    borderColor: 'primary',
    fontWeight: 'bold',
    display: 'flex',
    ':hover': {
      opacity: '0.4',
      backgroundColor: 'transparent',
      color: 'grey'
    }
  }
}
